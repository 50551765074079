<template>
  <main id="error-code" class="next-container error-page">
    <section-base>
      <h1 class="color-primary-100 heading-col">
        {{ currentError.message || defaultError.message }}
      </h1>

      <div class="text-col color-accent-300">
        <p>
          {{ errorParagraph.message }}

          <a
            v-if="errorParagraph.showMail"
            href="mailto://strompakete@eprimo.de"
          >
            strompakete@eprimo.de
          </a>
        </p>

        <p>
          Sie erreichen uns unter der Telefonnummer
          <strong>0800-204011093</strong>
        </p>

        <small class="text-small">
          Diese Rufnummer ist aus dem deutschen Festnetz kostenlos. Unsere
          Service-Zeiten sind montags –<br />
          freitags von 9.00 – 18.00 Uhr.
        </small>

        <button-regular class="button-accent" @click="openEprimo">
          eprimo.de besuchen
        </button-regular>
      </div>
    </section-base>
  </main>
</template>

<script>
import { mapState } from 'vuex';
import ButtonRegular from '@/components/next/button-regular.vue';
import SectionBase from '@/components/next/section-base.vue';

export default {
  components: { ButtonRegular, SectionBase },

  props: {
    /** @type {{ message: String, showMail?: Boolean }} */
    errorParagraph: Object
  },

  data: () => ({
    defaultError: {
      status: false,
      message: 'Dieser Code ist nicht mehr gültig'
    }
  }),

  computed: {
    ...mapState('user', ['currentError'])
  },

  methods: {
    openEprimo() {
      window.open('https://eprimo.de');
    }
  }
};
</script>

<style lang="scss" src="@/assets/styles/next/index.scss" scoped></style>
