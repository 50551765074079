import { render, staticRenderFns } from "./ErrorPage.vue?vue&type=template&id=46d4e042&scoped=true&"
import script from "./ErrorPage.vue?vue&type=script&lang=js&"
export * from "./ErrorPage.vue?vue&type=script&lang=js&"
import style0 from "@/assets/styles/next/index.scss?vue&type=style&index=0&id=46d4e042&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46d4e042",
  null
  
)

export default component.exports